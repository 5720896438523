import { useState, useEffect } from 'react';

import { Box, Stack, Typography, Grid, AppBar, Toolbar, IconButton, List, Popover } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PhoneIcon from '@mui/icons-material/Phone';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EastIcon from '@mui/icons-material/East';
import { useTranslation } from 'react-i18next';
import Masonry from '@mui/lab/Masonry';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import '../App.css';
import BasicTabs from '../components/tabnavigation';
import { LanguageSelector } from '../components/LanguageSelector'
import ImgMediaCard from '../components/siteCard';
import { useResponsive } from '../hooks/useResponsive';
import { siteList } from '../siteList';
import { Footer } from '../containers/Footer';
import { config } from '../config';

export const SiteHome = () => {
  const {isDesktop} = useResponsive();

  const { i18n, t } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  const [position, setPosition] = useState('acceuil');
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [ariaExpandedMenu, setAriaExpandedMenu] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAriaExpandedMenu(true);
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAriaExpandedMenu(false);
    setAnchorElNav(null);
  };

  const scrollToSection = (id) => {
    const violation = document.getElementById(id); 
    if (violation) {
      window.scrollTo({
        top: violation.offsetTop - 150,
        behavior:"smooth"
    });
  }
  }

  const handlePosition = (value) => {
    setPosition(value);
    scrollToSection(value)
    if (!isDesktop) {
      handleCloseNavMenu()
    }
  }

  useEffect(() => {
    const urlHash = window.location.hash;
    if (urlHash.length) {
      const urlIdSection = urlHash.substring(1);
      setPosition(urlIdSection)
      const element = document.getElementById(urlIdSection);
      if (element) {
       setTimeout(() => {
        window.scrollTo({
          top: element.offsetTop - 100,
          behavior:"smooth"
        }, );
       }, 100)
      }
    }
  }, [])

  useEffect(() => {
    document.title=`${t('home.title')} | ${t('common.aria-logo')}`
}, [t])

  return (
    <div className="App">
      <AppBar
        sx={{
          display: 'flex',
          backgroundColor: 'white', 
          paddingY: '1em',
          maxWidth: '100%'
        }}
        position='fixed'
        role="banner"
      >
        <Toolbar>
            {isDesktop ? (
              <Box sx={{display: 'flex', width: '100%', minHeight: 'max-content'}}>
                <Box sx={{display: 'flex', flexGrow: 1, width: '100%'}}>
                <Stack sx={{display: 'flex', alignItems: 'center', width: '100%'}} direction="row" spacing={10}>
                  <object 
                    role='img'
                    aria-label={t('common.aria-logo')}
                    width='130em'
                    type="image/svg+xml"
                    data="logo-herault-mobility.svg"
                    title={t('common.aria-logo')}
                  >
                  </object>
                  <nav role='navigation' aria-label={t('common.aria-label-menu')} style={{display: 'flex', alignItems: 'center'}}>
                    <List sx={{display: 'flex', width: '100%'}}>
                      <BasicTabs
                        position={position}
                        handlePosition={handlePosition}
                      />
                    </List>
                   </nav>
                </Stack>
              </Box>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <LanguageSelector/>
              </Box>
              </Box>
            ) : (
              <Box sx={{display: 'flex', width: '100%', paddingX: '.5em', alignItems: 'center'}}>
                <Box sx={{display: 'flex', flexGrow: 1}}>
                  <Box 
                      component="img"
                      sx={{
                        paddingX: '0.5em',
                        width: '6em'
                      }} 
                      src="logo-herault-mobility.png"
                      alt={t('common.aria-logo')}
                    />
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', marginX: '1em'}}>
                  <LanguageSelector/>
                </Box>
                <nav role='navigation' aria-label={t('common.aria-label-menu')} >
                  <Popover
                    id='menu-principal'
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    PaperProps={{
                      id:'menu-principal',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: 'block' ,
                      paddingX: 2
                    }}
                  >
                    <BasicTabs 
                      position={position}
                      handlePosition={handlePosition}
                    />
                  </Popover>
                  <IconButton
                      id='button-menu'
                      size="large"
                      sx={{
                        color: '#0d58a6',
                        border: '1px solid #0d58a6'
                      }}
                      aria-haspopup={true}
                      aria-expanded={ariaExpandedMenu}
                      aria-controls='menu-principal'
                      aria-label={t('common.aria-label-menu')}
                      onClick={handleOpenNavMenu}
                  >
                    <MenuIcon />
                  </IconButton>
                </nav>
              </Box>
            )}
        </Toolbar>
      </AppBar>
      <main role="main">
        <Box
          id='acceuil' 
          sx={{
            width: '100%',
            height: isDesktop ? 600: 400
          }}>
          <Box
            component="img"
            sx={{
              display: 'block',
              width: '100%',
              height: '100%',
              left: 0,
              top: 0,
              objectFit: 'cover',
              position: 'relative',
              zIndex: -1
            }}
            alt=""
            src="balarucxquentien.jpg"
          />
        </Box>
        <Box 
          sx={{ 
            zIndex: 1,
            maxWidth: isDesktop ? '50%': '90%',
            height: 'auto',
            backgroundColor: '#fff',
            paddingTop: '20px', 
            paddingBottom: '20px', 
            marginTop: '-100px',
            marginLeft: isDesktop ? '100px': '5%'
          }}
        >
          <Typography variant="h1" sx={{fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '3.2rem': '3rem', 'padding': 1}}>
          {t('common.title')}
          </Typography>
        </Box>

        <Box 
          maxWidth='85%'
          height='auto'
          margin='auto'
          marginTop={5}
        >
          <Typography gutterBottom={true} paragraph={true} sx={{fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.3rem': '1rem'}}>
          {t('common.first-intro')}
          </Typography>
          <Typography gutterBottom={true} paragraph={true} sx={{fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.3rem': '1rem'}}>
          {t('common.second-intro')}
          </Typography>
          <Accordion sx={{ marginTop: '1.5em' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content1"
              id="panel1a-header1"
            >
              <Stack direction="row" spacing={1}>
                <ContactSupportIcon sx={{color: '#0d58a6', fontSize: isDesktop ? '1.7rem': '1.2rem'}}/>
                <Typography component='h2' gutterBottom={true} sx={{color: '#0d58a6', fontFamily: 'Luciole-Bold-Italic', fontSize: isDesktop ? '1.2rem': '1rem'}}>
                {t('common.first-question')}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Typography gutterBottom={true} paragraph={true} sx={{fontFamily: 'Luciole', fontSize: isDesktop ? '1.2rem': '1rem'}}>
              {t('common.first-paragraph')}
              </Typography>
              <Typography gutterBottom={true} paragraph={true} sx={{fontFamily: 'Luciole', fontSize: isDesktop ? '1.2rem': '1rem'}}>
              {t('common.second-paragraph')}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ marginTop: '1.5em' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content2"
                id="panel1a-header2"
            >
              <Stack direction="row" spacing={1}>
                <ContactSupportIcon sx={{color: '#0d58a6', fontSize: isDesktop ? '1.7rem': '1.2rem'}}/>
                <Typography component='h2' gutterBottom={true} sx={{color: '#0d58a6', fontFamily: 'Luciole-Bold-Italic', fontSize: isDesktop ? '1.2rem': '1rem'}}>
                {t('common.second-question')}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Typography gutterBottom={true} paragraph={true} sx={{fontFamily: 'Luciole', fontSize: isDesktop ? '1.2rem': '1rem'}}>
              {t('common.third-paragraph')}
              </Typography>
              <Typography gutterBottom={true} paragraph={true} sx={{fontFamily: 'Luciole', fontSize: isDesktop ? '1.2rem': '1rem'}}>
              {t('common.fourth-paragraph')}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ marginTop: '1.5em' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content3"
                id="panel1a-header3"
            >
              <Stack direction="row" spacing={1}>
                <ContactSupportIcon sx={{color: '#0d58a6', fontSize: isDesktop ? '1.7rem': '1.2rem'}}/>
                <Typography component='h2' gutterBottom={true} sx={{color: '#0d58a6', fontFamily: 'Luciole-Bold-Italic', fontSize: isDesktop ? '1.2rem': '1rem'}}>
                {t('common.third-question')}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Typography gutterBottom={true} paragraph={true} sx={{fontFamily: 'Luciole', fontSize: isDesktop ? '1.2rem': '1rem'}}>
              {t('common.fifth-paragraph')}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ marginTop: '1.5em' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content4"
                id="panel1a-header4"
            >
              <Stack direction="row" spacing={1}>
                <ContactSupportIcon sx={{color: '#0d58a6', fontSize: isDesktop ? '1.7rem': '1.2rem'}}/>
                <Typography component='h2' gutterBottom={true} sx={{color: '#0d58a6', fontFamily: 'Luciole-Bold-Italic', fontSize: isDesktop ? '1.2rem': '1rem'}}>
                {t('common.fourth-question')}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Typography gutterBottom={true} paragraph={true} sx={{fontFamily: 'Luciole', fontSize: isDesktop ? '1.2rem': '1rem'}}>
              {t('common.sixth-paragraph')}
              </Typography>
              <Typography gutterBottom={true} paragraph={true} sx={{fontFamily: 'Luciole', fontSize: isDesktop ? '1.2rem': '1rem'}}>
              {t('common.seventh-paragraph')}
              </Typography>
              <Typography gutterBottom={true} paragraph={true} sx={{fontFamily: 'Luciole', fontSize: isDesktop ? '1.2rem': '1rem'}}>
              {t('common.eighth-paragraph')}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Typography paragraph={true} sx={{fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.2rem': '1rem', paddingTop: '1.5em'}}>
          {t('common.last-word')}
          </Typography>
          <Typography gutterBottom={true} paragraph={true} sx={{fontFamily: 'Luciole', fontSize: isDesktop ? '1.2rem': '1rem'}}>
          {t('common.last-paragraph-first-part')} <a href="https://www.herault-tourisme.com/fr/handitourisme/">handitourisme</a>{t('common.last-paragraph-second-part')}
          </Typography>
          <Typography gutterBottom={true} paragraph={true} sx={{fontFamily: 'Luciole', fontSize: isDesktop ? '1.2rem': '1rem'}}>
          {t('common.nb-message')}
          </Typography>
        </Box>

        <Box
          id='sites'  
          sx={{
            display: 'flex',
            maxWidth: '85%',
            height: 'auto',
            margin: 'auto',
            marginTop: 5
          }}
        >
          <Stack direction="row" spacing={1}>
            <TravelExploreIcon sx={{fontSize: isDesktop ? '2.5rem': '2rem'}}/>
            <Typography variant="h2" sx={{fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '2.2rem': '1.8rem'}}>
              {t('site.title')}
            </Typography>
          </Stack>
          
        </Box>
        <Box 
          sx={{
            display: 'flex',
            maxWidth:'90%',
            height:'auto',
            margin:'auto',
            marginTop: 5,
          }}
        >
          <Masonry columns={{ xs:1, sm: 1, md: 2, lg: 3, xl: 4}} spacing={3}>
            { siteList.map((site, index) => {
              let textLang = site.textFrDesc;
              if (getLanguage()==='fr') {
                textLang = site.textFrDesc;
              }
              else if (getLanguage()==='en') {
                textLang = site.textEnDesc;
              }
              else if (getLanguage()==='es') {
                textLang = site.textEsDesc;
              }
              return(
                <Grid id={index} key={index} item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <ImgMediaCard title={site.title} textDesc={textLang} image={site.image} credits={site.credits} grandSite={site.grandSite} destinationPourTous={site.destinationPourTous} />
                </Grid>
                )
              })
            }
          </Masonry>
        </Box>
        <Stack sx={{ paddingLeft: isDesktop ? 10: 2, paddingTop: isDesktop ? 5:3 }} direction="row" spacing={1}>
          <EastIcon sx={{fontSize: isDesktop ? '1.8rem': '1.2rem', color: '#0d58a6'}}/>
          <Typography variant="body1" style={{ fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.2rem': '1rem' }}>
            <a style={{ color: '#0d58a6' }} href={`${config.url.HANDIMAP_URL}?zone=Département de l'Hérault&lng=${i18n.language}`}>{t('site.herault')}</a>
          </Typography>
        </Stack>
        <Box 
          id='numbers'
          width='100%'
          height='auto'
          margin='auto'
          paddingTop={isDesktop ? 5: 3}
          paddingBottom={isDesktop ? 5: 3}
          marginTop={isDesktop ? 5: 3}
          backgroundColor='#0A4585'
          sx={{display: 'flex', alignItems: 'center'}}
        > 
          <Box paddingLeft={isDesktop ? 10: 2} marginBottom={isDesktop ? 2 : 4}>
            <Stack direction="row" spacing={1}>
              <PhoneIcon sx={{color: '#fff', fontSize: isDesktop ? '2.5rem': '2rem'}}/>
              <Typography variant="h2" sx={{color: '#fff', fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '2.2rem': '1.6rem'}}>
              {t('phone.title')}
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Box 
          width='100%'
          height='auto'
          margin='auto'
          paddingBottom={isDesktop ? 3 : 2}
          backgroundColor='#0A4585'
          sx={{display: 'flex', alignItems: 'center'}}
        > 
          <Box paddingLeft={isDesktop ? 10: 2}>
            <Stack direction="row" spacing={1}>
                <LocalHospitalIcon sx={{color: '#fff', fontSize: isDesktop ? '2rem': '1.5rem'}}/>
              <Typography variant="h3" sx={{color: '#fff', fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.8rem': '1.3rem'}}>
              {t('phone.rescue-title')}
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Box 
          width='100%'
          height='auto'
          margin='auto'
          backgroundColor='#0A4585'
        >
          <Box 
            maxWidth='90%'
            height='auto'
            margin='auto'
          >
            <Grid component="ul" container spacing={2}>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1.3rem': '1rem' }}>{t('phone.emergencies')}: <a style={{ color: '#97c11f'}} href="tel:112">112</a></Typography>
              </Grid>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1.3rem': '1rem' }}>{t('phone.emergencies-hearing')}: <a style={{ color: '#97c11f'}} href="tel:114">114</a></Typography>
              </Grid>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1.3rem': '1rem' }}>Samu: <a style={{ color: '#97c11f'}} href="tel:15">15</a></Typography>
              </Grid>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1.3rem': '1rem' }}>{t('phone.police')}: <a style={{ color: '#97c11f'}} href="tel:17">17</a></Typography>
              </Grid>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1.3rem': '1rem' }}>{t('phone.firefighters')}: <a style={{ color: '#97c11f'}} href="tel:18">18</a></Typography>
              </Grid>
            </Grid>
          </Box>

          <Box paddingBottom={5} paddingTop={10} paddingLeft={isDesktop ? 10: 2}>
            <Stack direction="row" spacing={1}>
              <PhoneAndroidIcon sx={{color: '#fff', fontSize: isDesktop ? '2rem': '1.5rem'}}/>
              <Typography variant="h3" sx={{color: '#fff', fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.8rem': '1.3rem'}}>
              {t('phone.other-title')}
              </Typography>
            </Stack>
          </Box>

          <Box 
            maxWidth='90%'
            height='auto'
            margin='auto'
            paddingBottom={5}
          >
            <Grid component="ul" container spacing={2}>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1rem': '0.8rem' }}>Comité de Liaison et de Coordination des associations de Personnes en Situation de Handicap: <a style={{ color: '#97c11f'}} href="tel:04 67 22 57 13">04 67 22 57 13</a></Typography>
              </Grid>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1rem': '0.8rem' }}>Comité Départemental de Sport Adapté: <a style={{ color: '#97c11f'}} href="tel:04 67 67 42 86">04 67 67 42 86</a></Typography>
              </Grid>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1rem': '0.8rem' }}>Handisport: <a style={{ color: '#97c11f'}} href="tel:04 67 67 42 84">04 67 67 42 84</a></Typography>
              </Grid>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1rem': '0.8rem' }}>Roule Nature: <a style={{ color: '#97c11f'}} href="tel:04 99 53 02 45">04 99 53 02 45</a></Typography>
              </Grid>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1rem': '0.8rem' }}>GIHP Hérault: <a style={{ color: '#97c11f'}} href="tel:04 67 20 59 45">04 67 20 59 45</a></Typography>
              </Grid>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1rem': '0.8rem' }}>GIHP Bassin de Thau: <a style={{ color: '#97c11f'}} href="tel:04 67 53 33 34">04 67 53 33 34</a></Typography>
              </Grid>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1rem': '0.8rem' }}>GIHP La Domitienne: <a style={{ color: '#97c11f'}} href="tel:04 67 09 22 89">04 67 09 22 89</a></Typography>
              </Grid>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1rem': '0.8rem' }}>GIHP Pays de l'Or: <a style={{ color: '#97c11f'}} href="tel:04 67 20 94 91">04 67 20 94 91</a></Typography>
              </Grid>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1rem': '0.8rem' }}>GIHP Montpellier: <a style={{ color: '#97c11f'}} href="tel:04 67 20 94 94">04 67 20 94 94</a></Typography>
              </Grid>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1rem': '0.8rem' }}>TAM (Montpellier): <a style={{ color: '#97c11f'}} href="tel:04 67 22 87 87">04 67 22 87 87</a></Typography>
              </Grid>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1rem': '0.8rem' }}>Hôpital Béziers: <a style={{ color: '#97c11f'}} href="tel:04 67 35 70 35">04 67 35 70 35</a></Typography>
              </Grid>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1rem': '0.8rem' }}>
                CHU Montpellier: <a style={{ color: '#97c11f'}} href="tel:04 67 33 67 33">04 67 33 67 33</a>
                </Typography>
              </Grid>
              <Grid component="li" sx={{listStyleType: 'none'}} item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Typography variant="body1" style={{ color: '#FFFFFF', fontFamily: 'Luciole', fontSize: isDesktop ? '1rem': '0.8rem' }}>Hôpital de Sète: <a style={{ color: '#97c11f'}} href="tel:04 67 46 57 57">04 67 46 57 57</a></Typography>
              </Grid>
            </Grid>
          </Box>
          
        </Box>

        <Box 
          id='beach'
          width='100%'
          height='auto'
          margin='auto'
          paddingTop={isDesktop ? 5: 3}
          paddingBottom={isDesktop ? 5: 3}
          marginTop={isDesktop ? 5: 3}
          backgroundColor='white'
          sx={{display: 'flex', alignItems: 'center'}}
        > 
          <Box paddingLeft={isDesktop ? 10: 2} marginBottom={isDesktop ? 2 : 4}>
            <Stack direction="row" spacing={1}>
              <BeachAccessIcon sx={{fontSize: isDesktop ? '2.5rem': '2rem'}}/>
              <Typography variant="h2" sx={{color: 'black', fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '2.2rem': '1.6rem'}}>
                {t('beach.title')}
              </Typography>
            </Stack>
            <Stack component="ul" sx={{marginTop: '2em', marginLeft: '1em'}} direction="column" spacing={1}>
              <Stack component="li" direction="row" spacing={1}>
                <PictureAsPdfIcon sx={{color: '#0d58a6', fontSize: isDesktop ? '1.5rem': '1.2rem'}}/>
                <Typography gutterBottom={true} paragraph={true} sx={{color: '#0d58a6', fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.2rem': '1rem'}}>
                  <a style={{ color: '#0d58a6'}} href="https://cdt34.media.tourinsoft.com/upload/journee-a-la-plage-balaruclesbains-2022-ok.pdf" target="_blank" rel="noreferrer">Balaruc les bains <span className="hidden">PDF</span></a>
                </Typography>
              </Stack>
              <Stack component="li" direction="row" spacing={1}>
                <PictureAsPdfIcon sx={{color: '#0d58a6', fontSize: isDesktop ? '1.5rem': '1.2rem'}}/>
                <Typography gutterBottom={true} paragraph={true} sx={{color: '#0d58a6', fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.2rem': '1rem'}}>
                  <a style={{ color: '#0d58a6'}} href="https://cdt34.media.tourinsoft.com/upload/1-journee-a-la-plage-capdagde-2022-der--1-.pdf" target="_blank" rel="noreferrer">Cap d’Agde <span className="hidden">PDF</span></a>
                </Typography>
              </Stack>
              <Stack component="li" direction="row" spacing={1}>
                <PictureAsPdfIcon sx={{color: '#0d58a6', fontSize: isDesktop ? '1.5rem': '1.2rem'}}/>
                <Typography gutterBottom={true} paragraph={true} sx={{color: '#0d58a6', fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.2rem': '1rem'}}>
                  <a style={{ color: '#0d58a6'}} href="https://cdt34.media.tourinsoft.com/upload/1-journee-a-la-plage-frontignan-2022.pdf" target="_blank" rel="noreferrer">Frontignan <span className="hidden">PDF</span></a>
                </Typography>
              </Stack>
              <Stack component="li" direction="row" spacing={1}>
                <PictureAsPdfIcon sx={{color: '#0d58a6', fontSize: isDesktop ? '1.5rem': '1.2rem'}}/>
                <Typography gutterBottom={true} paragraph={true} sx={{color: '#0d58a6', fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.2rem': '1rem'}}>
                  <a style={{ color: '#0d58a6'}} href="https://cdt34.media.tourinsoft.com/upload/1-journee-a-la-plage-grde-motte2022ter.pdf" target="_blank" rel="noreferrer">La Grande-Motte <span className="hidden">PDF</span></a>
                </Typography>
              </Stack>
              <Stack component="li" direction="row" spacing={1}>
                <PictureAsPdfIcon sx={{color: '#0d58a6', fontSize: isDesktop ? '1.5rem': '1.2rem'}}/>
                <Typography gutterBottom={true} paragraph={true} sx={{color: '#0d58a6', fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.2rem': '1rem'}}>
                  <a style={{ color: '#0d58a6'}} href="https://cdt34.media.tourinsoft.com/upload/1-journee-a-la-plage-marseillan-2022-ok.pdf" target="_blank" rel="noreferrer">Marseillan <span className="hidden">PDF</span></a>
                </Typography>
              </Stack>
              <Stack component="li" direction="row" spacing={1}>
                <PictureAsPdfIcon sx={{color: '#0d58a6', fontSize: isDesktop ? '1.5rem': '1.2rem'}}/>
                <Typography gutterBottom={true} paragraph={true} sx={{color: '#0d58a6', fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.2rem': '1rem'}}>
                  <a style={{ color: '#0d58a6'}} href="https://cdt34.media.tourinsoft.com/upload/journee-a-la-plage-mauguio-carnon2022.pdf" target="_blank" rel="noreferrer">Mauguio Carnon <span className="hidden">PDF</span></a>
                </Typography>
              </Stack>
              <Stack component="li" direction="row" spacing={1}>
                <PictureAsPdfIcon sx={{color: '#0d58a6', fontSize: isDesktop ? '1.5rem': '1.2rem'}}/>
                <Typography gutterBottom={true} paragraph={true} sx={{color: '#0d58a6', fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.2rem': '1rem'}}>
                  <a style={{ color: '#0d58a6'}} href="https://cdt34.media.tourinsoft.com/upload/journee-a-la-plage-meze-2020-3.pdf" target="_blank" rel="noreferrer">Mèze <span className="hidden">PDF</span></a>
                </Typography>
              </Stack>
              <Stack component="li" direction="row" spacing={1}>
                <PictureAsPdfIcon sx={{color: '#0d58a6', fontSize: isDesktop ? '1.5rem': '1.2rem'}}/>
                <Typography gutterBottom={true} paragraph={true} sx={{color: '#0d58a6', fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.2rem': '1rem'}}>
                  <a style={{ color: '#0d58a6'}} href="https://cdt34.media.tourinsoft.com/upload/journee-a-la-plage-palavas-2022-1ter.pdf" target="_blank" rel="noreferrer">Palavas les flots <span className="hidden">PDF</span></a>
                </Typography>
              </Stack>
              <Stack component="li" direction="row" spacing={1}>
                <PictureAsPdfIcon sx={{color: '#0d58a6', fontSize: isDesktop ? '1.5rem': '1.2rem'}}/>
                <Typography gutterBottom={true} paragraph={true} sx={{color: '#0d58a6', fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.2rem': '1rem'}}>
                  <a style={{ color: '#0d58a6'}} href="https://cdt34.media.tourinsoft.com/upload/journee-a-la-plage-Portiragnes-2022--5-.pdf" target="_blank" rel="noreferrer">Portiragnes <span className="hidden">PDF</span></a>
                </Typography>
              </Stack>
              <Stack component="li" direction="row" spacing={1}>
                <PictureAsPdfIcon sx={{color: '#0d58a6', fontSize: isDesktop ? '1.5rem': '1.2rem'}}/>
                <Typography gutterBottom={true} paragraph={true} sx={{color: '#0d58a6', fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.2rem': '1rem'}}>
                  <a style={{ color: '#0d58a6'}} href="https://cdt34.media.tourinsoft.com/upload/1-journee-a-la-plage-serignan-2022.pdf" target="_blank" rel="noreferrer">Sérignan <span className="hidden">PDF</span></a>
                </Typography>
              </Stack>
              <Stack component="li" direction="row" spacing={1}>
                <PictureAsPdfIcon sx={{color: '#0d58a6', fontSize: isDesktop ? '1.5rem': '1.2rem'}}/>
                <Typography gutterBottom={true} paragraph={true} sx={{color: '#0d58a6', fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.2rem': '1rem'}}>
                  <a style={{ color: '#0d58a6'}} href="https://cdt34.media.tourinsoft.com/upload/1-journee-a-la-plage-sete-2022-unlocked-ok.pdf" target="_blank" rel="noreferrer">Sète <span className="hidden">PDF</span></a>
                </Typography>
              </Stack>
              <Stack component="li" direction="row" spacing={1}>
                <PictureAsPdfIcon sx={{color: '#0d58a6', fontSize: isDesktop ? '1.5rem': '1.2rem'}}/>
                <Typography gutterBottom={true} paragraph={true} sx={{color: '#0d58a6', fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.2rem': '1rem'}}>
                  <a style={{ color: '#0d58a6'}} href="https://cdt34.media.tourinsoft.com/upload/1-journee-a-la-plage-valras2022-1.pdf" target="_blank" rel="noreferrer">Valras <span className="hidden">PDF</span></a>
                </Typography>
              </Stack>
              <Stack component="li" direction="row" spacing={1}>
                <PictureAsPdfIcon sx={{color: '#0d58a6', fontSize: isDesktop ? '1.5rem': '1.2rem'}}/>
                <Typography gutterBottom={true} paragraph={true} sx={{color: '#0d58a6', fontFamily: 'Luciole-Bold', fontSize: isDesktop ? '1.2rem': '1rem'}}>
                  <a style={{ color: '#0d58a6'}} href="https://cdt34.media.tourinsoft.com/upload/journee-a-la-plage-vias-2022der.pdf" target="_blank" rel="noreferrer">Vias <span className="hidden">PDF</span></a>
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>
        <Footer /> 
      </main>
    </div>
  );
}